import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        appDetails: {},
        settings: {
            active: true,
            theme: {
                font: {
                    variants: {
                        light: {
                            name: "",
                            file: "",
                        },
                        regular: {
                            name: "",
                            file: "",
                        },
                        bold: {
                            name: "",
                            file: "",
                        },
                        semi_bold: {
                            name: "",
                            file: "",
                        },
                        medium: {
                            name: "",
                            file: "",
                        },
                    },
                    family: "",
                },
                colors: {
                    primary_color: "#000000",
                    secondary_color: "#000000",
                    accent_color: "#000000",
                    link_color: "#000000",
                    button_secondary_color: "#000000",
                    bg_color: "#000000",
                },
            },
            widget_settings: {
                title: "Title",
                sub_title: "",
                max_products: 4,
                logo: null,
                title_color: "#000000",
                subtitle_color: "#000000",
                margin_bottom: 3,
            },
            product_card_layout: {
                style: "card-layout-1",
            },
            product_card_style: {
                border_radius: 4,
                background_color: "#ffffff",
                padding: 12,
                box_shadow: false,
                margin_right: 10,
                width:300,
                height:100,
            },
            product_image: {
                height: 100,
                scale: true,
            },
            product_title: {
                active: true,
                font_size: 14,
                text_color: "#41434c",
                secondary_text_color: "#41434c",
                alignment: "left",
                text_limit: 15,
                multiline_product_name: true,
                highlight_brand_name: true,
                layout: "layout1",
            },
            reviews: {
                active: true,
                show_count: true,
                show_stars: true,
                star_color: "#f2c94c",
                provider: "review-ratings",
                keymap: {
                    ratings_total: "rating_sum",
                    ratings_count: "rating_count",
                    reviews_count: "review_count",
                },
            },
            discount: {
                active: true,
                background_color: "#41434C",
                text_color: "#FFFFFF",
                text_size: 14,
                position: "bottom-left",
                text_orientation: "vertical",
            },
            price: {
                active: true,
                text_color: "#41434c",
                secondary_text_color: "#41434c",
                text_size: 14,
                alignment: "left",
                layout: "layout1",
            },
            wishlist: {
                active: true,
                color: "#ee478d",
            },
            product_highlights: {
                active: true,
                font_size: 12,
                font_color: "#000000",
                highlight_count: 3,
                margin_top: 4,
                border_bottom: true,
            },
            cart_button: {
                active: true,
                font_size: 16,
                label: "Add to cart",
                label_color: "#ffffff",
                background_color: "#41434c",
                hover_color: "#ffffff",
            },
            new_config: {
                product_card_style: {
                    border: false,
                    border_stroke: 5,
                    border_colour: "#ffffff",
                    // padding: {
                    //     top: 12,
                    //     bottom: 12,
                    //     left: 12,
                    //     right: 12,
                    // },
                    border_radius: 4,
                    background_color: "#ffffff",
                    padding: 12,
                    box_shadow: false,
                    margin_right: 10,
                    width:300,
                    height:100,
                },
                global_text_settings: {
                    font: "Poppins",
                    font_weight: "normal",
                    font_size: 10,
                    font_colour: "#000000",
                    font_style: {
                        bold: true,
                        italic: true,
                        subscript: false,
                    },
                },
                widget_settings: {
                    title_alignment: "left",
                    title_font_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 15,
                        font_colour: "#000000",
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                            underline: false,
                        },
                        title_color: "#000000",
                        subtitle_color: "#000000",
                        margin_bottom: 3,
                    },
                    title_bottom_spacing: 10,
                    sub_title_alignment: "left",
                    sub_title_font_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 10,
                        font_colour: "#000000",
                        sub_heading_active: true,
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                            underline: false,
                        },
                    },
                    sub_title_bottom_spacing: 10,
                },
                price: {
                    selling_price_font_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 10,
                        font_colour: "#000000",
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                            underline: false,
                        },
                    },
                    selling_price_bottom_spacing: 10,
                    old_selling_price_font_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 10,
                        font_colour: "#000000",
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                        },
                        strikethrough: false,
                    },
                    old_selling_price_bottom_spacing: 10,
                },
                product_title: {
                    product_title_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 10,
                        font_colour: "#000000",
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                            underline: false,
                        },
                    },
                    product_title_bottom_spacing: 10,
                },
                brand_title: {
                    brand_title_settings: {
                        font: "Poppins",
                        font_weight: "normal",
                        font_size: 10,
                        font_colour: "#000000",
                        font_style: {
                            bold: true,
                            italic: true,
                            subscript: false,
                            underline: false,
                        },
                    },
                    brand_title_bottom_spacing: 10,
                },
                wishlist: {
                    position: "top-left",
                    border: {
                        active: true,
                        stroke_color: "#000000",
                        stroke_weight: 10,
                    },
                    fill: false,
                    active: false,
                },
                discount: {
                    font_style: {
                        bold: true,
                        italic: true,
                        underline: false,
                        size: 14,
                    },
                    padding: {
                        top: 12,
                        bottom: 12,
                        left: 12,
                        right: 12,
                    },
                },
                reviews: {
                    rating_size: "M",
                    review_font_size: 14,
                    review_font_colour: "#000000",
                    font_style: {
                        bold: true,
                        italic: true,
                        underline: false,
                        size: 14,
                    },
                },
                product_highlights: {
                    font_style: {
                        bold: true,
                        italic: true,
                        underline: false,
                        size: 14,
                    },
                    bottom_spacing: 10,
                    border_colour: "#000000",
                },
            },
        },
    },
    getters: {},
    mutations: {
        updateAppDetails(state, newAppDetails) {
            state.appDetails = {
                ...newAppDetails,
            };
        },
        updateConfig(state, data) {
            state.settings = data;
        },
    },
    actions: {
        async getConfig({ commit }, data) {
            commit("loaderChange", true);
            try {
                let res = await MainService.getAllconfigure(data);
                commit("updateConfig", res.data);
                commit("loaderChange", false);
                return true;
            } catch (err) {
                commit("loaderChange", false);
                return false;
            }
        },
        async addConfig({ commit, state }, data) {
            commit("loaderChange", true);
            try {
                await MainService.addConfigure(data);
                commit("loaderChange", false);
                return true;
            } catch (error) {
                commit("loaderChange", false);
                return false;
            }
        },
    },
    modules: {},
});

export default store;
