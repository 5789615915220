const state = { companyId: -1, applicationId: '' };

const getGetter = key => () => state[ key ];
const getSetter = key => value => ( state[ key ] = value ); // eslint-disable-line no-return-assign

export const getCompany = getGetter( 'companyId' );
export const setCompany = getSetter( 'companyId' );

export const getAppId = getGetter( 'applicationId' );
export const setAppId = getSetter( 'applicationId' );

export const getUserId = getGetter( 'userId' );
export const setUserId = getSetter( 'userId' );

export const getCompanyBasePath = route => `/company/${
    ( route?.params?.company_id ) || getCompany()
}`;

export const getAppBasePath = route => `${ getCompanyBasePath( route ) }/application/${
    ( route?.params?.application_id ) || getAppId()
}`;

export function debounce( func, timeout = 500 ) {

    let timer;
    return ( ...args ) => {

        clearTimeout( timer );
        timer = setTimeout( () => {

            func.apply( this, args );

        }, timeout );

    };

}

export function throttle( func, timeout = 1500 ) {

    let wait = false;
    return ( ...args ) => {

        if ( wait ) {

            return;

        }

        wait = true;
        func.apply( this, args );
        setTimeout( () => {

            wait = false;

        }, timeout );

    };

}
