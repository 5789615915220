import Vue from "vue";
import VueRouter from "vue-router";
import { setCompany, setAppId } from "../utils/index.js";

Vue.use(VueRouter);

const routes = [
  {
    name: "welcome_view",
    path: "/company/:companyId/welcome",
    component: () => import("../views/WelcomeView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      next();
    },
  },
  {
    name: "distribution-channel",
    path: "/company/:companyId/application/:applicationId/distribution-channel/:slug",
    component: () =>
      import("../views/SlotDistributionChannel/SlotDistributionChannel.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "sales_channel_view",
    path: "/company/:companyId",
    component: () => import("../views/SalesChannelView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      next();
    },
  },
  {
    name: "feature_view",
    path: "/company/:companyId/application/:applicationId",
    component: () => import("../views/SalesChannelView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "feature_view_application",
    path: "/company/:companyId/application/:applicationId/featureView",
    component: () => import("../views/FeatureView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "settings_view",
    path: "/company/:companyId/application/:applicationId/settings",
    component: () => import("../views/SettingsView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "settings_view_list_virtual_store",
    path: "/company/:companyId/application/:applicationId/virtual-store/:slug",
    component: () => import("../views/VirtualStoreMainList.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "collection-settings",
    path: "/company/:companyId/application/:applicationId/collection-settings/:slug",
    component: () => import("../views/VirtualStoreCollectionSettings.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "virtual-store-configuration",
    path: "/company/:companyId/application/:applicationId/collection-settings/:slug/virtual-store-configuration",
    component: () => import("../views/VirtualStoreConfigSettings.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "virtual-store-index-config",
    path: "/company/:companyId/application/:applicationId/collection-settings/:slug/index-configuration/:indexSlug",
    component: () =>
      import("../views/IndexConfiguration/IndexConfiguration.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "general-settings",
    path: "/company/:companyId/application/:applicationId/settings-list",
    component: () =>
      import(
        "../views/VirtualStoreGeneralSettings/VirtualStoreGeneralSettings.vue"
      ),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "account_view",
    path: "/company/:companyId/application/:applicationId/account/:slug",
    component: () => import("../views/AccountView/AccountView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "config_page_view",
    path: "/company/:companyId/application/:applicationId/config-page",
    component: () => import("../views/ConfigurationView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "tournaments_list_view",
    path: "/company/:companyId/application/:applicationId/tournaments",
    component: () => import("../views/TournamentsListView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
    },
  },
  {
    name: "account_view",
    path: "/company/:companyId/application/:applicationId/account/:slug",
    component: () => import("../views/AccountView/AccountView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "symbols-list",
    path: "/company/:companyId/application/:applicationId/symbols",
    component: () => import("../views/SymbolsListView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "symbols_create_view",
    path: "/company/:companyId/application/:applicationId/symbols/create",
    component: () => import("../views/SymbolsCreateEditView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "symbols_edit_view",
    path: "/company/:companyId/application/:applicationId/symbols/:symbolId",
    component: () => import("../views/SymbolsCreateEditView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "page-config",
    path: "/company/:companyId/application/:applicationId/page-config",
    component: () => import("../views/PageConfigView.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
  {
    name: "ui-config",
    path: "/company/:companyId/application/:applicationId/ui-config",
    component: () => import("../views/ConfigureMenu.vue"),
  },
  {
    name: "games-distribution",
    path: "/company/:companyId/application/:applicationId/games-distribution/:slug",
    component: () => import("../views/DistributionChannel.vue"),
    beforeEnter(to, _, next) {
      setCompany(to.params.companyId);
      setAppId(to.params.applicationId);
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
