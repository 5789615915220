<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import Vue from "vue";

    export default {
        async mounted() {
            let NitrozenSnackbar = await import("@gofynd/nitrozen-vue/src/components/NSnackbar")
            Vue.use(NitrozenSnackbar.default);
            Vue.snackbar.register("show", (message) => message, {
                position: "top-center",
                duration: 1500,
            });
            Vue.snackbar.register("showSuccess", (message) => message, {
                position: "top-center",
                duration: 1500,
                type: "success",
            });
            Vue.snackbar.register("showError", (message) => message, {
                position: "top-center",
                duration: 1500,
                type: "error",
            });
            Vue.snackbar.register("showWarning", (message) => message, {
                position: "top-center",
                duration: 1500,
                type: "warning",
            });
            Vue.snackbar.register("showInfo", (message) => message, {
                position: "top-center",
                duration: 1500,
                type: "info",
            });
        },
    };
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import url('./assets/css/style.css');

#app {
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
